/* eslint-disable jsx-a11y/control-has-associated-label */

import React from "react"
import { Link } from "gatsby"

import { Layout, Seo } from "../layout"
import { FindOutMoreButton } from "../components"

export default function IndexPage(props) {
  const main_cell = "px-3 sm:px-8 py-3 text-xs sm:text-lg"
  const main_cell_2 = main_cell + " bg-gray-200"

  return (
    <Layout path={props.path}>
      <Seo title="Sales" />

      <table className="table-auto mt-32 my-24 mx-auto border border-gray-400 shadow">
        <tbody>
          <tr>
            <td className={main_cell}>
              <Link to="/no/products/presence#service">
                Din bedrift i Trailguide-appen
              </Link>
            </td>
            <td className={main_cell + " text-right"}>kr 4.000 per år</td>
            <td className={main_cell + " text-center"}>
              <FindOutMoreButton href="https://trailguide.net/order?lang=no">
                Bestill her
              </FindOutMoreButton>
            </td>
          </tr>

          <tr>
            <td className={main_cell_2}>
              <Link to="/no/products/presence#resort">
                Din resort i Trailguide-appen
              </Link>
            </td>
            <td className={main_cell_2 + " text-right"}>kr 8.000 per år</td>
            <td className={main_cell_2}>
              <EmailUs />
            </td>
          </tr>

          <tr>
            <td className={main_cell}>
              <Link to="/no/products/presence#destination">
                Din turistdestinasjon i Trailguide-appen
              </Link>
            </td>
            <td className={main_cell + " text-right"}>kr 15.000 per år</td>
            <td className={main_cell}>
              <EmailUs />
            </td>
          </tr>

          <tr>
            <td className={main_cell_2}>
              <Link to="/no/products/presence#region">
                Din turistregion i Trailguide-appen
              </Link>
            </td>
            <td className={main_cell_2 + " text-right"}>kr 30.000 per år</td>
            <td className={main_cell_2}>
              <EmailUs />
            </td>
          </tr>

          <tr>
            <td className={main_cell}>
              <Link to="/products/plugin">Trailguide integrasjon</Link>
            </td>
            <td className={main_cell + " text-right"}>
              kr 5.000 per år/domene
            </td>
            <td className={main_cell}>
              <EmailUs />
            </td>
          </tr>

          <tr>
            <td className={main_cell_2}>
              <Link to="/products/landingpage">Trailguide landingsside</Link>
            </td>
            <td className={main_cell_2 + " text-right"}>kr 5.000 per år</td>
            <td className={main_cell_2}>
              <EmailUs />
            </td>
          </tr>

          <tr>
            <td className={main_cell}>
              <Link to="/no/products/#wall_map">Skreddersydd veggkart</Link>
            </td>
            <td className={main_cell + " text-right"}>kr 20.000</td>
            <td className={main_cell}>
              <EmailUs />
            </td>
          </tr>

          <tr>
            <td className={main_cell_2}>
              <Link to="/no/products/#activity_manager">Activity Manager</Link>
            </td>
            <td className={main_cell_2 + " text-right"}>kr 5.000 per år</td>
            <td className={main_cell_2}>
              <EmailUs />
            </td>
          </tr>

          <tr>
            <td className={main_cell}>
              <Link to="/no/products/#collection_manager">
                Collection Manager
              </Link>
            </td>
            <td className={main_cell + " text-right"}>kr 5.000 per år</td>
            <td className={main_cell}>
              <EmailUs />
            </td>
          </tr>

          <tr>
            <td className={main_cell_2}>Ekstra Trailguide adminbruker</td>
            <td className={main_cell_2 + " text-right"}>
              kr 2.000 per bruker/år
            </td>
            <td className={main_cell_2}>
              <EmailUs />
            </td>
          </tr>
        </tbody>
      </table>
    </Layout>
  )
}

function EmailUs() {
  return (
    <>
      sales
      <span className="hidden">If you see this, apply for a job</span>@
      <span className="hidden">as a programmer at Trailguide</span>
      trailguide.no
    </>
  )
}
